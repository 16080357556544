// main
var userAgent = window.navigator.userAgent.toLowerCase();

var imgLoad = imagesLoaded(document);
var count = 0;
var img__length = imgLoad.images.length;

//layzr
var layzr = Layzr({
  normal: 'data-layzr',
  threshold: 50
});

layzr.on('src:after', function (elm) {
  if(!elm.hasAttribute('data-layzr-bg')) return;
  elm.style.backgroundImage = 'url(' + elm.getAttribute('src') + ')';
  elm.removeAttribute('src');
  elm.removeAttribute('data-layzr-bg');
});

document.addEventListener('DOMContentLoaded', function() {
    layzr.update().check().handlers(true)
 }, false);

//loader
imgLoad.on('progress',function(){
    count++
    if(count <= img__length){
        var il = Math.floor(100/img__length*count) + '%';
        setTimeout(function(){
            //バー
            $('#js-loader-progress-bar').css('width',il);
        },500); 
    };
}).on('always',function(){

  loader_anime();
});

//Webfont
$(window).on('load', function() {
  WebFont.load({
    google: {
      families: ['Amiri']
    },
    custom: {
      families: ['Noto+Serif+JP'],
      urls: ['https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,500&display=swap&subset=japanese']
    },
  });
});

//scroll周り
$('.js-pagetop').on('click tap', function() {
  $('body, html').animate({
    scrollTop:0
  }, 500, 'linear');
});

$(window).on('load scroll', function() {

  if( $('.js-pagetop')[0] ) {
    var h = $(window).height();
    var scrollTop = $(window).scrollTop();
    if (scrollTop > h / 2) {
      $('.js-pagetop').addClass('is-active');
    }
    else {
      $('.js-pagetop').removeClass('is-active');
    }
  }
});

$(window).on('load', function() {

  //ハンバーガーメニュー
  $('.js-menu-toggle,.p-sp-menu-container').on('click tap', function() {


    var status = $('body').attr('data-menu');
    if( status == 'active' ) {
      $('body').attr('data-menu', '');
      $('body').css({"overflow":"visible","height":"auto"});
      
        var acordion_status = $('.js-menu-acordion-toggle').parent().attr('data-menu');

        if( acordion_status == 'active' ) {//アコーディオンも閉じる
          $('.js-menu-acordion-toggle').parent().attr('data-menu', '');
          $('.js-menu-acordion-toggle').parent().find('.js-menu-acordion-container').slideToggle();
        }

        $('#js-sp-menu').removeClass('is-active')
  
    } else {
			$('body').css({"overflow":"hidden","height":"100%"});
      $('body').attr('data-menu', 'active');
      
      setTimeout(function(){
        $('#js-sp-menu').addClass('is-active')
      },500)
    }
  });

  //ハンバーガーメニューアコーディオン
  $('.js-menu-acordion-toggle').on('click tap', function(e) {
    e.stopPropagation();
    var status = $(this).parent().attr('data-menu');

    if( status == 'active' ) {

      $(this).parent().attr('data-menu', '');
      $(this).parent().find('.js-menu-acordion-container').slideToggle();

    } else {

      $(this).parent().attr('data-menu', 'active');
      $(this).parent().find('.js-menu-acordion-container').slideToggle();

    }
  });

});

$(window).on('load', function(){

  //円形タイトル調整
  circle_container('.c-schedule-ttl-container','.c-schedule-ttl',2);
  circle_container('.c-circle-ttl-container','.c-circle-ttl',1.5);

  //縦書きタイトル調整
  ttl_container('.c-blog-ttl-container','.c-blog-ttl-main','.c-blog-ttl-sub');

  //縦書きタイトルブログのasideだけ統一
  var blog_ttl_width = 0;

  ttl_check(function(){
    $('.js-blog-ttl-container').each(function(){
      $(this).width(blog_ttl_width);
    })
  })

  function ttl_check(callback){
    $('.js-blog-ttl').each(function(){
  
      var ttl_width = $(this).outerWidth();
      if(blog_ttl_width < ttl_width){
        blog_ttl_width = ttl_width;
      }
    })
    callback(blog_ttl_width);
  }

});

$(window).on('load resize', function(){

    var window_size = window.innerWidth;

    //match height
    $('.p-global-footer-contact-ttl').matchHeight({
      byRow: false
    });
  
    $('.p-global-footer-contact-btn').matchHeight({
      byRow: false
    });

    if(window_size < 1366){
      
      //SP時の当店のこだわり部分の調整
      setTimeout(function(){
        var photo_height = $('.js-about-photo').innerHeight();
        var photo_height_top = $('.js-about-photo-item').eq(1).innerHeight() * .4;
        var photo_height_num = $('.js-about-photo-item').length;

        if(photo_height_num > 1){
          $('.js-about-photo').css('margin-bottom',-photo_height + photo_height_top);
          $('.js-about-point').css('padding-top',photo_height - photo_height_top / 2);
        
        }else{
          $('.js-about-photo-item').css('justify-content','center');
        }

      },100)

    }
})

$(window).on('load scroll resize', function(){
  var window_size = window.innerWidth;
  var header_height = $('#js-header-container').outerHeight();

    //ヘッダー高さ分l-mainをずらす
    setTimeout(function(){
      var header_height_change = $('#js-header').outerHeight();
      $('.l-main').css('padding-top',header_height_change);
    
    },200);

    setTimeout(function(){  
      
      //メガメニューの位置調整
      var header_height_container_change = $('#js-header-container').outerHeight();

      if(window_size > 991){ 
        var child_hegiht = $('.p-gnav-item').eq(1).height();
        $('.p-gnav-item-child-container').css('padding-top', Math.floor((header_height_container_change + child_hegiht) / 2));
  
      }
    },500);

    //グロナビ高さを変化させる
    if(window_size > 1366){

      var header_pos = $('#js-header').offset().top + header_height;
      var mv_height = $('.c-subpage-header').outerHeight() + header_height;

      if(!mv_height){
        mv_height = $('.p-home-header').outerHeight() + header_height;
      }

      if(header_pos > mv_height){
        $('#js-header').addClass('is-overed');
      
      }else{
        $('#js-header').removeClass('is-overed');
      
      }
    }

})

//scroll
$(document).ready(function() {
  var hHeight = $('header').outerHeight();
  var urlHash = location.hash;
  var speed = 500;

  //ハッシュ値があればページ内スクロール
  if(urlHash) {
    //スクロールを0に戻す
    $('body,html').stop().scrollTop(0);
    setTimeout(function () {
      //ロード時の処理を待ち、時間差(1000ミリ秒)でスクロール実行
      var target = $(urlHash);
      var position = target.offset().top - hHeight;
      $('body,html').stop().animate({scrollTop:position}, speed, "swing");
    }, 500);
  }

  // クリック時
  $('a[href]').click(function(e){
    var anchor = $(this),
    href = anchor.attr('href'),
    pagename = window.location.href;
    
    // ハンバーガーメニューが開いている場合は閉じる
    if(href.match(/#.*/) && $('body').attr('data-menu') == 'active'){
      $('body').attr('data-menu', '');

      var acordion_status = $('.js-menu-acordion-toggle').parent().attr('data-menu');
      if( acordion_status == 'active' ) {
        $('.js-menu-acordion-toggle').parent().attr('data-menu', '');
      }
    }
    // 現在のurlのハッシュ以降を削除
    pagename = pagename.replace(/#.*/,'');

    // リンク先のurlから現在の表示中のurlを削除
    href = href.replace( pagename , '' );

    if( href.search(/^#/) >= 0 ){
      // 整形したリンクがページ内リンクの場合はページ内スクロールの対象とする
      // 通常の遷移処理をキャンセル
      e.preventDefault();

      // 前段階で整形したhrefを使用する
      // var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top - hHeight;
      $("html, body").animate({scrollTop:position}, speed, "swing");

      // ロケーションバーの内容を書き換え
      location.hash = href ;
      return false;
    }
  })
});

//携帯判別
function is_mobile() {
  if (
    (navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) ||
    navigator.userAgent.indexOf('iPod') > 0 ||
    navigator.userAgent.indexOf('Android') > 0
  ) {
    return true;
  }
  else {
    return false;
  }
}

//円形タイトル調整
function circle_container(container,main_block,magni){
  var container_size = 0;

  setTimeout(function(){
    $(container).each(function(){
      var container_width = $(this).width();
      var container_height = $(this).height();
      
      if(container_width > container_height){
        if(container_size < container_width){
          container_size =  container_width;
        }
      }else{
        if(container_size < container_height){
          container_size =  container_height;
        }
      }
    });

    container_size = Math.floor(container_size * magni);

    $(main_block).each(function(){
      $(this).css({height:container_size + 'px', width:container_size + 'px'});
    });
  },100)
};

//縦書きタイトル調整
function ttl_container(container,main_block,sub_block){
  setTimeout(function(){
    var ttl_container = $(main_block).width();
    var sub_container = $(sub_block).width();
    
    if(ttl_container > sub_container){
      $(container).width(ttl_container * 1.2);
    }else{
      $(container).width(sub_container * 1.2);
    }
  },100)
};

//ロード
function loader_anime(){
  loader()
  .then(loader_action02)
  .then(loader_action03)
  .then(loader_action04)
  .then(loader_swiper);
};
    
function loader(){
  var is_front = $('body').filter('.is-front-page').length;
  var d = $.Deferred();

  if(is_front) {
    var h = window.innerHeight;
    
    setTimeout(function(){
        $('#js-loader-progress-bar').css('transform','scaleY(' + h + ')');
        d.resolve();
    },1000);
  } else {
    d.resolve();
  }
  return d.promise();
}

function loader_action02(){
  var d = $.Deferred();

  setTimeout(function(){
    $('#js-loader-curtin').addClass('is-loaded');
    $('#js-loader-progress-bar').css('opacity','0');
    d.resolve();
  },1000);
  return d.promise();
}

function loader_action03(){
    var d = $.Deferred();
    setTimeout(function(){   
          $('body').addClass('is-loaded');
        d.resolve();
    },500);
    return d.promise();
}

function loader_action04(){
  var d = $.Deferred();
  setTimeout(function(){   
        $('.p-home-header-ttl').addClass('is-loaded');
        $('.js-schedule').addClass('is-loaded');
        $('.js-mainvisual-btn').addClass('is-loaded');
        $('.p-home-header-scroll').addClass('is-loaded')
      d.resolve();
  },200);
  return d.promise();
}

function loader_swiper(){
  var d = $.Deferred();
  setTimeout(function(){
    var mv_slider = new Swiper ('.js-mv-swiper-container', {
      loop: true,
      slidesPerView: "auto",
      autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        }
    });
  },1000)
  return d.promise();
}
